import { render, staticRenderFns } from "./AdminLayout.vue?vue&type=template&id=47628ec7&"
import script from "./AdminLayout.js?vue&type=script&lang=js&"
export * from "./AdminLayout.js?vue&type=script&lang=js&"
import style0 from "./AdminLayout.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QBtnDropdown,QAvatar,QList,QItem,QItemSection,QIcon,QItemLabel,QPageContainer,QBtn,QTabPanel});qInstall(component, 'directives', {ClosePopup,Ripple});
